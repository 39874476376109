export default (module: string, componentName: string) => {
    const config = useConfig();

    return defineAsyncComponent(async () => {
        try {
            return await import(`~/locale/${config.locale}/${module}/${componentName}.vue`);
        } catch (error) {
            return () => {};
        }
    });
};
